
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';

@Component
export default class RatesGraphLabel extends Vue {
    @Inject(UserServiceS) private userService!: UserService;

    get compsetType() {
        return this.$t('filterSettings.compset.median');
    }

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get getIconColor() {
        const color = this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';
        return `background-color: ${color}`;
    }
}
